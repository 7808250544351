.container {
  background-color: white;
  padding-top: 5vh;
  height: 95vh;
  position: relative;
}

.innerContainer {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -45%);
  width: 90vw;
}

.header {
  display: flex;
  justify-content: flex-end;
  margin: 1% 5% 0;
}

.insights {
  width: 65vw;
}

.insightsImg {
  max-width: 100%;
  height: auto;
}

.gskLogo {
  width: 6.5rem;
  height: auto;
  object-fit: contain;
}

.titleContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.subtitleContainer {
  padding-top: 2rem;
}

.subtitle {
  padding-top: 0.5rem;
  font-weight: 700;
  font-size: 1.5rem;
  text-align: center;
}

.problemContainer {
  font-weight: 600;
  font-size: 1.2rem;
  text-align: center;
  position: absolute;
  bottom: 5%;
  left: 7%;
  color: #f36633;
  width: 3rem;
}

.scanButtonContainer {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  color: #f36633;
}

.scanButton {
  all: unset;
  font-size: 1.5rem;
  cursor: pointer;
}

.scanImg {
  height: 5rem;
}

.fadeInAndOut {
  opacity: 1;
  animation: fade 5s linear infinite;
}

@keyframes fade {
  0%,
  100% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
}

@media (min-width: 1400px) {
  .insights {
    width: 50vw;
  }
  .subtitleContainer {
    padding-top: 0;
  }
}

.inputScanner {
  all: unset;
  background-color: rgb(0, 0, 0, 0);
  color: rgb(0, 0, 0, 0);
}
