.header {
  display: flex;
  justify-content: space-between;
  padding: 3rem 4.5rem 0;
}

.insightsContainer {
  display: flex;
  flex-direction: column;
}

.your {
  text-align: left;
  font-weight: 600;
  font-size: 1.1rem;
}

.insights {
  text-align: center;
  font-weight: 800;
  font-size: 1.7rem;
  color: #ee7623;
}

.matter {
  text-align: right;
  font-weight: 600;
  font-size: 1.1rem;
}

.gskLogo {
  object-fit: contain;
  width: 6.5rem;
}

.insightsLogo {
  width: 11rem;
  height: auto;
}
