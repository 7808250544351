.container {
  height: 100vh;
  width: 100vw;
  background: linear-gradient(to top, #ddd7d4, #f8f7f7);
  position: relative;
}

.innerContainer {
  background-color: white;
  position: absolute;
  width: 92vw;
  height: 89vh;
  margin-top: 1rem;
  border-radius: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.question {
  font-weight: 400;
  font-size: 2rem;
  padding: 3rem 0 3rem 10%;
}

.contentContainer {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
}

.questionContainer {
  color: #f36633;
}

.spacer {
  min-width: 3.5rem;
}

.bottomText {
  display: flex;
  font-size: 1.8rem;
}

.optionsContainer {
  height: 10rem;
  position: absolute;
  width: 100%;
}

.lineContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
  width: 100%;
}

.line {
  width: 90%;
  height: 1px;
  background-color: #f36633;
  border: 1px solid #f36633;
}

.buttonsContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonsInnerContainer {
  position: absolute;
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.optionButton {
  width: 3.5rem;
  height: 3.5rem;
  background-color: white;
  border: 4px solid #f36633;
  border-radius: 100px;
  cursor: pointer;
}

.optionButtonSelected {
  width: 3.5rem;
  height: 3.5rem;
  border: 5px solid #ee7623;
  background-color: #ee7623;
  border-radius: 100px;
  cursor: pointer;
}

.buttonText {
  padding-top: 0.5rem;
  position: absolute;
  color: #868686;
  font-weight: 500;
  font-size: 1rem;
  width: 6rem;
  max-width: 6rem;
  text-align: center;
  margin-left: -1rem;
}

.continueButtonContainer {
  position: absolute;
  bottom: 8%;
  left: 80%;
}

.text1 {
  font-weight: 600;
  padding-bottom: 0.5rem;
}

.text2 {
  padding-left: 2.5rem;
}

.barContainer {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}

.radioContainer {
  padding-top: 1rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.flexStart {
  display: flex;
  align-items: center;
  margin: 2rem 0;
}

.radioButtonField {
  -ms-transform: scale(3);
  -webkit-transform: scale(3);
  transform: scale(3.7);
  width: 1.3rem;
  margin: 2rem 2.5rem;
  cursor: pointer;
  accent-color: #f36633;
}

.radioText {
  font-size: 2rem;
  padding: 0 2rem;
  font-weight: 500;
  color: #868686;
}

.instructions {
  padding-left: 26%;
  padding-top: 0.5rem;
  font-size: 1.2rem;
}
