.continueButton {
  padding: 1rem 2.7rem;
  font-weight: lighter;
  border-radius: 10px;
  font-size: 1.4rem;
  background-color: #f36633;
  color: white;
  border: 3px solid #f36633;
  cursor: pointer;
}

.disabledButton {
  padding: 1rem 2.7rem;
  font-weight: lighter;
  border-radius: 10px;
  font-size: 1.4rem;
  background-color: #9d9795;
  color: white;
  border: 3px solid #9d9795;
  cursor: pointer;
}

.continueButton:hover {
  background-color: white;
  color: #f36633;
  border: 3px solid #f36633;
}
