.header {
  display: flex;
  justify-content: space-between;
  padding-top: 1.5rem;
  padding-left: 5rem;
  padding-right: 5rem;
}

.container {
  position: relative;
  padding-top: 2vh;
  height: 98vh;
  background: linear-gradient(to top, #ddd7d4, #f8f7f7);
  color: #5a5a5a;
}

.innerContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
}

.insightsContainer {
  width: 70%;
  display: block;
  margin: auto;
}

.insights {
  text-align: center;
  font-weight: 700;
  font-size: 4rem;
  color: #f36633;
  line-height: 1.4;
}

.matter {
  text-align: right;
  font-weight: 600;
  font-size: 4.4rem;
}

.your {
  text-align: left;
  font-weight: 600;
  font-size: 4.4rem;
}

.gskLogo {
  object-fit: contain;
}

.titleContainer {
  display: flex;
  justify-content: center;
}

.subtitle {
  margin: 2rem 0;
  font-weight: 400;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
}

.subtitle1 {
  padding-top: 2rem;
  padding-left: 20rem;
  padding-right: 20rem;
  font-weight: 600;
  font-size: 1.5rem;
  text-align: center;
}

.subtitle2 {
  position: absolute;
  bottom: 7%;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 600;
  font-size: 1.5rem;
  text-align: center;
  color: #ee7623;
  text-decoration: none;
}

.beginContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  padding-right: 4rem;
}

.fadeInAndOut {
  opacity: 1;
  animation: fade 5s linear infinite;
}

@keyframes fade {
  0%,
  100% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
}

.gskLearningContainer {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.computerClick {
  height: 2.5rem;
}

.copyright {
  height: 2.2rem;
}

.shareText {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
}

.copyImageContainer {
  width: 50%;
  display: block;
  margin-left: auto;
}

.copyLogoContainer {
  position: absolute;
  bottom: 7%;
  right: 5%;
  color: #5a5a5a;
  font-weight: 600;
  width: 25rem;
}

.copyrightText {
  font-weight: 700;
  font-size: 0.45rem;
  text-align: right;
  padding-bottom: 0.5rem;
}

.imgFluid {
  max-width: 100%;
  height: auto;
}

.imgGskContainer {
  width: 43%;
  display: block;
  margin: auto;
}
