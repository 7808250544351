.container {
  background-color: white;
  height: 100vh;
  position: relative;
}

.formContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60vw;
}

.input {
  padding: 1.2rem 1rem;
  width: 90%;
  border: 2px solid #f36633;
  border-radius: 5px;
  background-color: rgb(0, 0, 0, 0);
  margin: 1rem 0;
  font-size: 1.5rem;
}

.flexContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.emailField {
  font-size: 1.5rem;
  padding: 1.2rem 1rem;
  width: 100%;
  border: 2px solid #f36633;
  border-radius: 5px;
  background-color: rgb(0, 0, 0, 0);
  margin: 1rem 0rem;
}

.inputsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.btnContainer {
  width: 30%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.validationMessage {
  color: #e93137;
  font-size: 1.1rem;
  font-weight: 600;
}

.flexStart {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 1rem 0;
}

.emailContainer {
  width: 92%;
  padding-right: 8%;
}

.btnSubmit {
  all: unset;
  padding: 1.2rem 4rem;
  font-weight: 500;
  border-radius: 7px;
  font-size: 2rem;
  background-color: #f36633;
  color: white;
  cursor: pointer;
  margin-bottom: 0.65rem;
}

.disabledButton {
  all: unset;
  padding: 1.2rem 4rem;
  font-weight: 500;
  border-radius: 7px;
  font-size: 2rem;
  cursor: pointer;
  margin-bottom: 0.65rem;
  background-color: #9d9795;
  color: white;
}

.btnSubmit:hover {
  background-color: white;
  color: #f36633;
  border: 3px solid #f36633;
}

.firstNameContainer {
  width: 45%;
  margin-right: 5%;
}

.lastNameContainer {
  width: 45%;
  margin-left: 5%;
}

.textFields {
  color: #f36633;
  font-weight: 700;
  font-size: 1.2rem;
  padding-left: 1rem;
}

::placeholder {
  color: black;
  font-size: 1.1rem;
  opacity: 1;
}
